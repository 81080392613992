
import {defineComponent, onMounted, reactive, ref, watch } from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import DateTime from "@/components/common/DateTime.vue";
import Currency from "@/components/common/Currency.vue";
import SubscriptionGatewayLink from "@/components/common/SubscriptionGatewayLink.vue";
import SubscriptionStatus from "@/components/subscriptions/SubscriptionStatus.vue";
import SubscriptionEditModal from "@/components/modals/forms/SubscriptionEditModal.vue";

export default defineComponent({
  name: "subscriptions-listing",
  components: {
    SubscriptionStatus,
    SubscriptionGatewayLink,
    Currency,
    DateTime,
    SubscriptionEditModal
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Assinaturas", []);
      load()
    });

    const tableData = ref([]);
    const filterProperties = ref({
      email: "",
      subscriptionId: "",
      statusId: "ACTIVE"
    });

    let searching;
    const filterList = (): void => {
      clearTimeout(searching)
      searching = setTimeout(() => load(), 700)
    };

    const load = (): void => {
      ApiService.setHeader();
      ApiService.get(`v1/backend/subscriptions?page=${pagination.page}`, {
        params: filterProperties.value
      })
          .then(({data}) => {
            tableData.value = data.data;
            pagination.page = data.current_page;
            pagination.total = data.total;
          })
          .catch(({response}) => {
            console.log(response);
          });
    };

    const pagination = reactive({
      page: 1,
      total: 0,
    })

    return {
      tableData,
      filterList,
      load,
      filterProperties,
      pagination,
    };
  },
});
