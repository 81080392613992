import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1 mx-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = { class: "d-flex align-items-center position-relative my-1 mx-1" }
const _hoisted_7 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_8 = { class: "d-flex align-items-center position-relative my-1 mx-1" }
const _hoisted_9 = { class: "card-body pt-0" }
const _hoisted_10 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_11 = { class: "table-responsive" }
const _hoisted_12 = {
  class: "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",
  role: "grid"
}
const _hoisted_13 = { class: "odd" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { key: 3 }
const _hoisted_18 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_19 = { class: "align-text-top" }
const _hoisted_20 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "align-text-top text-end" }
const _hoisted_23 = { class: "align-text-top text-end" }
const _hoisted_24 = { class: "align-text-top" }
const _hoisted_25 = { class: "align-text-top" }
const _hoisted_26 = { class: "align-text-top" }
const _hoisted_27 = { class: "align-text-top text-end" }
const _hoisted_28 = ["data-bs-target"]
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" }
const _hoisted_31 = { class: "col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_DateTime = _resolveComponent("DateTime")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Currency = _resolveComponent("Currency")!
  const _component_SubscriptionGatewayLink = _resolveComponent("SubscriptionGatewayLink")!
  const _component_SubscriptionStatus = _resolveComponent("SubscriptionStatus")!
  const _component_SubscriptionEditModal = _resolveComponent("SubscriptionEditModal")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterProperties.email) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterList())),
            class: "form-control form-control-solid w-300px ps-15",
            placeholder: "Pesquisar assinatura por email"
          }, null, 544), [
            [_vModelText, _ctx.filterProperties.email]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterProperties.subscriptionId) = $event)),
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterList())),
            class: "form-control form-control-solid w-300px ps-15",
            placeholder: "ID da Assinatura"
          }, null, 544), [
            [_vModelText, _ctx.filterProperties.subscriptionId]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_select, {
            class: "form-select-solid",
            placeholder: "Todas as Situações",
            modelValue: _ctx.filterProperties.statusId,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterProperties.statusId) = $event)),
            onChange: _ctx.load,
            clearable: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "Ativa",
                value: "ACTIVE"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Ativa")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                label: "Cancelada",
                value: "CANCELLED"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Cancelada")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                label: "Suspensa",
                value: "SUSPENDED"
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Suspensa")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                label: "Expirada",
                value: "EXPIRED"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Expirada")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                label: "Aguardando Pagamento",
                value: "PENDING"
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Aguardando Pagamento")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("table", _hoisted_12, [
            _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
              _createElementVNode("tr", {
                class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",
                role: "row"
              }, [
                _createElementVNode("th", null, "ID"),
                _createElementVNode("th", null, "Data de Início"),
                _createElementVNode("th", null, "Data de Renovação"),
                _createElementVNode("th", null, "Pacote"),
                _createElementVNode("th", null, "Usuário"),
                _createElementVNode("th", null, "Dispositivo"),
                _createElementVNode("th", null, "Valor de Adesão"),
                _createElementVNode("th", null, "Valor de Assinatura"),
                _createElementVNode("th", null, "Gateway"),
                _createElementVNode("th", null, "ID Gateway"),
                _createElementVNode("th", null, "Situação"),
                _createElementVNode("th", { class: "text-end" }, "Ações")
              ])
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (subscription) => {
              return (_openBlock(), _createElementBlock("tbody", {
                class: "fw-bold text-gray-600",
                key: subscription.id
              }, [
                _createElementVNode("tr", _hoisted_13, [
                  _createElementVNode("td", null, _toDisplayString(subscription.id), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_DateTime, {
                      "date-time": subscription.created_at
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_DateTime, {
                      "date-time": subscription.renew_data
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", null, [
                    (subscription.package.months === 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, "1 Mês"))
                      : (subscription.package.months > 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(subscription.package.months) + " Meses", 1))
                        : (subscription.package.days === 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, "1 Dia"))
                          : (subscription.package.days > 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(subscription.package.days) + " Dias", 1))
                            : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    (subscription.user)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: `/apps/customers/customer-details/${subscription.user.id}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(subscription.user.email), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_18, "Não definido"))
                  ]),
                  _createElementVNode("td", _hoisted_19, [
                    (!subscription.mobile_id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, "Não vinculado à nenhum dispositivo"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createElementVNode("div", null, _toDisplayString(subscription.mobile.manufacturer) + " " + _toDisplayString(subscription.mobile.model) + " " + _toDisplayString(subscription.mobile.android_version), 1),
                          _createElementVNode("div", null, _toDisplayString(subscription.mobile.uid), 1)
                        ]))
                  ]),
                  _createElementVNode("td", _hoisted_22, [
                    _createVNode(_component_Currency, {
                      code: subscription.currency.code,
                      amount: subscription.setup_fee
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_23, [
                    _createVNode(_component_Currency, {
                      code: subscription.currency.code,
                      amount: subscription.price_recurring
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_24, _toDisplayString(subscription.gateway), 1),
                  _createElementVNode("td", _hoisted_25, [
                    _createVNode(_component_SubscriptionGatewayLink, { subscription: subscription }, null, 8, ["subscription"])
                  ]),
                  _createElementVNode("td", _hoisted_26, [
                    _createVNode(_component_SubscriptionStatus, {
                      status: subscription.status
                    }, null, 8, ["status"])
                  ]),
                  _createElementVNode("td", _hoisted_27, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-sm btn-block",
                      "data-bs-toggle": "modal",
                      "data-bs-target": `#subscriptionEdit-${subscription.id}`
                    }, " Editar ", 8, _hoisted_28),
                    _createVNode(_component_SubscriptionEditModal, {
                      onCallback: _ctx.load,
                      subscription: subscription,
                      "subscription-id": subscription.id,
                      "mobile-id": subscription.mobile_id,
                      "modal-id": `subscriptionEdit-${subscription.id}`
                    }, null, 8, ["onCallback", "subscription", "subscription-id", "mobile-id", "modal-id"])
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Total", -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.pagination.total), 1)
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createVNode(_component_el_pagination, {
              "current-page": _ctx.pagination.page,
              "onUpdate:currentPage": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pagination.page) = $event)),
              onCurrentChange: _ctx.load,
              "page-size": 10,
              layout: "prev, pager, next",
              total: _ctx.pagination.total,
              "hide-on-single-page": true,
              background: ""
            }, null, 8, ["current-page", "onCurrentChange", "total"])
          ])
        ])
      ])
    ])
  ]))
}